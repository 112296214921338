import { create } from "zustand";


export type ColorScheme = 'dark' | 'light';
interface SettingsStore {
  menuExpanded: boolean;
  toggleMenu: () => void;
  showMenu: () => void;
  content: React.ReactNode;
  setMenuContent: (c: React.ReactNode) => void;
  colorScheme: ColorScheme;
  setColorScheme: (scheme: ColorScheme) => void;
}

const { matches: isMobile } = window.matchMedia("(max-width: 678px)");

const useSettings = create<SettingsStore>((set, get) => ({
  menuExpanded: !isMobile,
  colorScheme: 'dark',
  content: null,
  setColorScheme: (colorScheme) => {
    set((state) => ({
      colorScheme: colorScheme
    }))
  },
  showMenu: () => {
    set((state) => ({
      menuExpanded: true
    }))
  },
  toggleMenu: () => {
    set((state) => ({
      menuExpanded: !state.menuExpanded
    }))
  },
  setMenuContent: (c) => {
    set((state) => ({
      content: c,
    }))
  }
}));

export default useSettings;
