import * as React from "react";
import { Link } from "react-router-dom";
import Text from "../Text";
import "./Card.scss";

type Props = {
  imgUrl: string;
  title: string;
  description: string;
  description2: string;
  to: string;
};

const Card: React.FC<Props> = (props) => {
  const { to, imgUrl, title, description, description2 } = props;
  return (
    <Link to={to} className="TDB-Card">
      <img className="TDB-Card__img" src={imgUrl} alt={title} />
      <div className="TDB-Card__main">
        <Text
          fontWeight="bold"
          as="h2"
          variant="heading-2"
          className="TDB-Card__title"
        >
          {title}
        </Text>
        <Text variant="body-md" as="p">
          {description}
        </Text>
        <Text variant="body-md" as="p">
          {description2}
        </Text>
      </div>
    </Link>
  );
};

export default Card;
