import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import useSettings from "../../store/SettingsStore";
import routerPaths from "../../utils/routerPaths";
import Text from "../Text";
import IconButton from "../IconButton";
import Union from "../Icons/Union";
import "./Sidebar.scss";
import classNames from "classnames";
import Close from "../Icons/Close";

interface Props extends React.PropsWithChildren {}

const Sidebar: React.FC<Props> = () => {
  const toggleMenu = useSettings((state) => state.toggleMenu);
  const menuContent = useSettings((state) => state.content);
  const menuExpanded = useSettings((state) => state.menuExpanded);

  React.useEffect(() => {
    // Wait 500ms to end the animation and trigger resize event
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }, [menuExpanded]);

  return (
    <div
      className={classNames("TDB-Sidebar", {
        "TDB-Sidebar--expanded": menuExpanded,
      })}
    >
      <div>
        <IconButton
          className="TDB-Sidebar__close"
          onClick={toggleMenu}
          Icon={Close}
        />
        <div className="TDB-Sidebar__top">
          <Link to={routerPaths.root}>
            <img className="TDB-Sidebar__logo" src={logo} alt="logo" />
          </Link>
          <IconButton
            className="TDB-Sidebar__button"
            onClick={toggleMenu}
            Icon={Union}
          />
        </div>
        {menuContent ? (
          menuContent
        ) : (
          <Text as="p" variant="body-md">
            Welcome to TileDB Point Cloud visualizations page. <br /> <br />{" "}
            Click on the panels to interactively view point cloud data in your
            browser using TileDB Cloud and BabylonJS.
          </Text>
        )}
      </div>
      <div>
        <Text variant="body-sm" color="text-main-tertiary">
          © TileDB {new Date().getFullYear()} Copyright
        </Text>
      </div>
    </div>
  );
};

export default Sidebar;
