import * as React from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import "./Page.css";

interface Props extends React.PropsWithChildren {
  /**
   * Pass true to render children when not logged-in
   */
  title?: string;
  className?: string;
}

const Page: React.FC<Props> = (props) => {
  const { children, title, className } = props;
  return (
    <div className={classNames("TDB-Page", className)}>
      <Helmet>
        <meta charSet="utf-8" />
        {title && <title>{title}</title>}
      </Helmet>
      {children}
    </div>
  );
};

export default Page;
