import * as React from "react";
import classNames from "classnames";
import "./Text.scss";

type Variant =
  | "display-1"
  | "heading-1"
  | "heading-2"
  | "body-lg"
  | "body-md"
  | "body-sm"
  | "subtext";

export type TextColorToken =
  | "text-main-primary"
  | "text-main-secondary"
  | "text-main-tertiary"
  | "text-main-disabled"
  | "text-main-subtle"
  | "text-action-primary"
  | "text-action-alternative"
  | "text-action-brand"
  | "text-deco-primary"
  | "text-deco-secondary"
  | "text-error";

type Element =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "a"
  | "small"
  | "div"
  | "pre";

type FontWeight = "normal" | "medium" | "semi-bold" | "bold";
interface Props extends React.HTMLProps<HTMLParagraphElement> {
  variant?: Variant;
  fontWeight?: FontWeight;
  color?: TextColorToken;
  className?: string;
  as?: Element;
  to?: string;
  align?: "left" | "center" | "right";
  hoverEffect?: "underline";
  dataTestId?: string;
  ellipsis?: boolean;
  noWrap?: boolean;
}

const Text: React.FC<Props> = ({
  children,
  className,
  as = "span",
  to,
  color = "text-main-secondary",
  fontWeight,
  align,
  href,
  style,
  hoverEffect,
  variant,
  dataTestId,
  ellipsis,
  noWrap,
  ...rest
}) => {
  const Component: any = as;
  const variantClassName = variant ? `TDB-Text--${variant}` : "";
  const fontWeightClassName = fontWeight ? `TDB-Text--fw-${fontWeight}` : "";
  const hoverEffectClassName = hoverEffect
    ? `TDB-Text--hover-${hoverEffect}`
    : "";

  return (
    <Component
      style={{ textAlign: align, color: `var(--${color})`, ...style }}
      className={classNames(
        "TDB-Text",
        fontWeightClassName,
        hoverEffectClassName,
        variantClassName,
        className,
        {
          "TDB-Text--ellipsis": ellipsis,
          "TDB-Text--no-wrap": noWrap,
        }
      )}
      to={to}
      href={href}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Text;
