import * as React from "react";
import Card from "../../components/Card";
import Page from "../../components/Page";
import links from "../../constants/links";
import routerPaths from "../../utils/routerPaths";
import autzenCover from "./assets/autzen_cover_light.png";
import bristolCover from "./assets/bristol_cover_light.png";
import santoriniCover from "./assets/santorini_cover_light.png";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import IconButton from "../../components/IconButton";
import Hamburger from "../../components/Icons/Hamburger";
import useSettings from "../../store/SettingsStore";
import "./Home.scss";

type Props = {};

const Home: React.FC<Props> = () => {
  const toggleMenu = useSettings((state) => state.toggleMenu);
  return (
    <Page className="TDB-Home">
      <header className="TDB-Home__header">
        <Logo />
        <IconButton Icon={Hamburger} onClick={toggleMenu} />
      </header>
      <div className="TDB-Home__title">
        <h1>Visualization examples</h1>
        <a
          className="btn TDB-Home__button"
          href={links.signup}
          target="_blank"
          rel="noreferrer"
        >
          Signup to TileDB
        </a>
      </div>
      <div className="TDB-Home__grid">
        <Card
          imgUrl={autzenCover}
          title="Autzen"
          description="The Autzen stadium in Oregon (US)"
          description2="10,653,336 points"
          to={routerPaths.autzen}
        />
        <Card
          imgUrl={bristolCover}
          title="Bristol"
          description="The river Avon in Bristol (UK)"
          description2="52,338,833 points"
          to={routerPaths.bristol}
        />
        <Card
          imgUrl={santoriniCover}
          title="Santorini"
          description="The Kameni islands in Santorini (Greece)"
          description2="35,491,876 points"
          to={routerPaths.santorini}
        />
      </div>
    </Page>
  );
};

export default Home;
