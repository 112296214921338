import * as React from "react";

type Props = {};

const Union: React.FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.30863 2.82889C7.17637 2.7236 7.01226 2.66638 6.84321 2.66663C6.64601 2.66625 6.45671 2.74412 6.31686 2.88315L2.0222 7.14873C1.8927 7.27651 1.7941 7.43217 1.73392 7.60386C1.67375 7.77554 1.65358 7.9587 1.67497 8.13936C1.71636 8.42892 1.85486 8.69583 2.06779 8.89637L6.31686 13.1168C6.39026 13.1895 6.47792 13.2462 6.57431 13.2833C6.6707 13.3204 6.77374 13.3372 6.87692 13.3325C6.98011 13.3279 7.08122 13.3019 7.17388 13.2562C7.26653 13.2106 7.34873 13.1462 7.41529 13.0673C7.53252 12.9153 7.58899 12.7251 7.57371 12.5337C7.55842 12.3424 7.47248 12.1636 7.33261 12.0321L4.03974 8.76135C4.03802 8.75964 4.03685 8.75746 4.03638 8.75508C4.03591 8.75271 4.03615 8.75024 4.03708 8.74801C4.03801 8.74577 4.03959 8.74386 4.04161 8.74252C4.04363 8.74118 4.046 8.74047 4.04843 8.74049H12.3334V12.6666C12.3334 13.0348 12.7064 13.3333 13.1667 13.3333C13.6269 13.3333 14 13.0348 14 12.6666V3.33329C14 2.9651 13.6269 2.66663 13.1667 2.66663C12.7064 2.66663 12.3334 2.9651 12.3334 3.33329V7.25946H4.04827C4.04585 7.25948 4.04347 7.25877 4.04145 7.25743C4.03943 7.25609 4.03786 7.25418 4.03693 7.25194C4.03599 7.2497 4.03575 7.24724 4.03622 7.24486C4.0367 7.24249 4.03787 7.2403 4.03959 7.23859L7.32059 3.97979C7.39971 3.90589 7.46259 3.81633 7.5052 3.7168C7.54782 3.61727 7.56925 3.50996 7.56812 3.4017C7.567 3.29344 7.54334 3.1866 7.49867 3.08798C7.45399 2.98937 7.38927 2.90113 7.30863 2.82889Z"
        fill="black"
      />
    </svg>
  );
};

export default Union;
