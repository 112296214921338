import * as React from "react";
import classNames from "classnames";
import "./IconButton.scss";

type Props = {
  onClick: () => void;
  Icon: any;
  className?: string;
};

const IconButton: React.FC<Props> = (props) => {
  const { onClick, Icon, className } = props;
  return (
    <div
      role="button"
      onClick={onClick}
      className={classNames("TDB-IconButton", className)}
    >
      <Icon />
    </div>
  );
};

export default IconButton;
