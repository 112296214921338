import * as React from "react";

type Props = {};

const Close: React.FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1132 12.0525C13.2538 12.1926 13.3329 12.3829 13.3332 12.5813C13.3335 12.7798 13.255 12.9703 13.1148 13.1108C12.9746 13.2514 12.7844 13.3305 12.5859 13.3308C12.3873 13.3311 12.1968 13.2526 12.0562 13.1124L7.99966 9.05693L3.94632 13.1124C3.87671 13.182 3.79407 13.2372 3.70311 13.2749C3.61216 13.3126 3.51467 13.3319 3.41622 13.3319C3.2174 13.3319 3.02671 13.253 2.88612 13.1124C2.74553 12.9719 2.66655 12.7812 2.66655 12.5825C2.66655 12.3837 2.74553 12.1931 2.88612 12.0525L6.94269 8.00023L2.88608 3.94473C2.74549 3.80417 2.6665 3.61354 2.6665 3.41476C2.6665 3.21599 2.74549 3.02536 2.88608 2.8848C3.02667 2.74425 3.21735 2.66528 3.41618 2.66528C3.61501 2.66528 3.80569 2.74425 3.94628 2.8848L7.99961 6.94031L12.0562 2.8848C12.1968 2.74467 12.3873 2.66612 12.5858 2.66643C12.7844 2.66673 12.9746 2.74586 13.1148 2.88642C13.255 3.02697 13.3335 3.21743 13.3332 3.41591C13.3329 3.61438 13.2538 3.8046 13.1132 3.94473L9.05985 8.00023L13.1132 12.0525Z"
        fill="black"
        fill-opacity="0.7"
      />
    </svg>
  );
};

export default Close;
