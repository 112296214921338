import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routerPaths from "./utils/routerPaths";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import classNames from "classnames";
import useSettings from "./store/SettingsStore";
import "./App.scss";

const Autzen = React.lazy(() => import("./pages/demos/Autzen"));

const Bristol = React.lazy(() => import("./pages/demos/Bristol"));

const Santorini = React.lazy(() => import("./pages/demos/Santorini"));

function App() {
  const menuExpanded = useSettings((state) => state.menuExpanded);
  return (
    <Router>
      <div className="App">
        <Sidebar />
        <main
          className={classNames("App__main", {
            "App__main--menuExpanded": menuExpanded,
          })}
        >
          <Suspense fallback={null}>
            <Routes>
              <Route path={routerPaths.root} element={<Home />} />
              <Route path={routerPaths.autzen} element={<Autzen />} />
              <Route path={routerPaths.bristol} element={<Bristol />} />
              <Route path={routerPaths.santorini} element={<Santorini />} />
            </Routes>
          </Suspense>
        </main>
      </div>
    </Router>
  );
}

export default App;
