import * as React from "react";

type Props = {};

const Hamburger: React.FC<Props> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_90_268)">
        <path
          d="M14.486 13.3333H1.73637C1.54128 13.3329 1.35431 13.2551 1.2165 13.117C1.07869 12.9789 1.0013 12.7918 1.0013 12.5967C1.0013 12.4016 1.07869 12.2145 1.2165 12.0764C1.35431 11.9383 1.54128 11.8605 1.73637 11.8601H14.486C14.6811 11.8605 14.8681 11.9383 15.0059 12.0764C15.1437 12.2145 15.2211 12.4016 15.2211 12.5967C15.2211 12.7918 15.1437 12.9789 15.0059 13.117C14.8681 13.2551 14.6811 13.3329 14.486 13.3333ZM14.486 8.74089H1.73637C1.54101 8.74089 1.35365 8.66328 1.21551 8.52514C1.07736 8.387 0.999756 8.19963 0.999756 8.00427C0.999756 7.80891 1.07736 7.62155 1.21551 7.4834C1.35365 7.34526 1.54101 7.26765 1.73637 7.26765H14.486C14.6814 7.26765 14.8687 7.34526 15.0069 7.4834C15.145 7.62155 15.2226 7.80891 15.2226 8.00427C15.2226 8.19963 15.145 8.387 15.0069 8.52514C14.8687 8.66328 14.6814 8.74089 14.486 8.74089ZM14.486 4.13991H1.73637C1.54128 4.1395 1.35431 4.06171 1.2165 3.92361C1.07869 3.78552 1.0013 3.59839 1.0013 3.40329C1.0013 3.20819 1.07869 3.02107 1.2165 2.88297C1.35431 2.74487 1.54128 2.66708 1.73637 2.66667H14.486C14.6811 2.66708 14.8681 2.74487 15.0059 2.88297C15.1437 3.02107 15.2211 3.20819 15.2211 3.40329C15.2211 3.59839 15.1437 3.78552 15.0059 3.92361C14.8681 4.06171 14.6811 4.1395 14.486 4.13991Z"
          fill="black"
          fill-opacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_268">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Hamburger;
